var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mb-6", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "6", md: "5" } }, [
            _c("div", { staticClass: "payment-field left" }, [
              _c("p", { staticClass: "subtitle-1 mb-2" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("shopinfo.topUp.amountTransfer")) + " "
                )
              ]),
              _c("p", { staticClass: "title mb-2 primary--text" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("formatNumber")(_vm.totalPrice)) +
                    " " +
                    _vm._s(_vm.$t("shopinfo.topUp.currency")) +
                    " "
                )
              ]),
              _c("div", { staticClass: "d-flex caption" }, [
                _c("span", { staticClass: "mr-3" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("shopinfo.topUp.totalAndVat")) + " "
                  )
                ]),
                _c("div", [
                  _c("p", { staticClass: "mb-1" }, [
                    _vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.vat3")) + " ")
                  ]),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("shopinfo.topUp.pleaseContact")) + " "
                    ),
                    _c("span", { staticClass: "green--text" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("shopinfo.topUp.contactLine")) + " "
                      )
                    ])
                  ])
                ])
              ]),
              _c("p", { staticClass: "subtitle-1 mb-5" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("shopinfo.topUp.vatInvoiceDetail")) + " "
                )
              ])
            ])
          ]),
          _c(
            "v-col",
            {
              class: _vm.xs ? "pt-3 pl-0" : "pl-5",
              attrs: { cols: "6", md: "7" }
            },
            [
              _c(
                "div",
                { staticClass: "d-flex payment-field mb-5" },
                [
                  _c(
                    "div",
                    { staticClass: "mr-5" },
                    [
                      _c("v-img", {
                        attrs: {
                          src:
                            "https://giftcard.scb.co.th/assets/images/scb-logo.png",
                          "max-width": "65"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", [
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("shopinfo.topUp.bankSCB")) + " "
                      )
                    ]),
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("shopinfo.topUp.scbBankName")) + " "
                      )
                    ]),
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("shopinfo.topUp.scbBankNumber")) +
                          " "
                      )
                    ])
                  ]),
                  _c("v-img", {
                    staticClass: "bank-img",
                    attrs: {
                      src: "/imgs/package/bank.png",
                      "max-width": _vm.xs ? 80 : 130
                    }
                  })
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "div",
                { staticClass: "d-flex payment-field" },
                [
                  _c(
                    "div",
                    { staticClass: "mr-5" },
                    [
                      _c("v-img", {
                        attrs: {
                          src:
                            "https://www.vtkgarment.com/images/customer/UOB.png",
                          "max-width": "65"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", [
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("shopinfo.topUp.bankUOB")) + " "
                      )
                    ]),
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("shopinfo.topUp.uobBankName")) + " "
                      )
                    ]),
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("shopinfo.topUp.uobBankNumber")) +
                          " "
                      )
                    ])
                  ]),
                  _c("v-img", {
                    staticClass: "bank-img",
                    attrs: {
                      src: "/imgs/package/bank.png",
                      "max-width": _vm.xs ? 80 : 130
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-end" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary", text: "" },
              on: {
                click: function($event) {
                  return _vm.backStep()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.back")) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", rounded: "", depressed: "" },
              on: {
                click: function($event) {
                  return _vm.nextStep()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.next")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }