var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "payment-field d-flex" },
      [
        _c("v-img", {
          staticClass: "mt-2 mr-5",
          attrs: {
            src: "https://giftcard.scb.co.th/assets/images/scb-logo.png",
            "max-width": "65",
            "max-height": "65"
          }
        }),
        _c("div", [
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.bankSCB")) + " ")
          ]),
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.scbBankName")) + " ")
          ]),
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.scbBankNumber")) + " ")
          ])
        ]),
        _c("v-img", {
          staticClass: "bank-img",
          attrs: { src: "/imgs/package/bank.png" }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "payment-field d-flex" },
      [
        _c("v-img", {
          staticClass: "mt-2 mr-5",
          attrs: {
            src: "https://www.vtkgarment.com/images/customer/UOB.png",
            "max-width": "65",
            "max-height": "50"
          }
        }),
        _c("div", [
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.bankUOB")) + " ")
          ]),
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.uobBankName")) + " ")
          ]),
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.uobBankNumber")) + " ")
          ])
        ]),
        _c("v-img", {
          staticClass: "bank-img",
          attrs: { src: "/imgs/package/bank.png" }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "text-right" },
      [
        _c(
          "v-btn",
          {
            attrs: { color: "primary", rounded: "", depressed: "" },
            on: {
              click: function($event) {
                return _vm.nextStep()
              }
            }
          },
          [
            _c("span", [
              _vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.next")) + " ")
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }