var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-up-success" },
    [
      _c("v-img", {
        staticClass: "mx-auto mb-2",
        attrs: { src: _vm.image.success, "max-width": "130" }
      }),
      _c("p", { staticClass: "primary--text" }, [
        _vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.transferPackage")) + " "),
        _vm.currentPackage
          ? _c("span", [_vm._v(" " + _vm._s(_vm.currentPackage.name) + " ")])
          : _vm._e(),
        _vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.success")) + " ")
      ]),
      _c("p", [
        _vm._v(
          " " + _vm._s(_vm.$t("shopinfo.topUp.pendingTransferPackage")) + " "
        )
      ]),
      _c("p", [
        _vm._v(
          " " + _vm._s(_vm.$t("shopinfo.topUp.transferToOpenPackage")) + " "
        )
      ]),
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary", rounded: "", depressed: "" },
              on: {
                click: function($event) {
                  return _vm.closeModal()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.success")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }