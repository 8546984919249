import { render, staticRenderFns } from "./Transfer.vue?vue&type=template&id=3ce2fc93&scoped=true&"
import script from "./Transfer.vue?vue&type=script&lang=js&"
export * from "./Transfer.vue?vue&type=script&lang=js&"
import style0 from "./Transfer.vue?vue&type=style&index=0&id=3ce2fc93&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ce2fc93",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VForm,VRow,VSelect,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ce2fc93')) {
      api.createRecord('3ce2fc93', component.options)
    } else {
      api.reload('3ce2fc93', component.options)
    }
    module.hot.accept("./Transfer.vue?vue&type=template&id=3ce2fc93&scoped=true&", function () {
      api.rerender('3ce2fc93', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ShopInfo/components/TopUpPackage/components/Transfer.vue"
export default component.exports