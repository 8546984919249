<template>
  <div>
    <v-row
      class="mb-6"
      no-gutters>
      <v-col
        cols="6"
        md="5">
        <div class="payment-field left">
          <p class="subtitle-1 mb-2">
            {{ $t('shopinfo.topUp.amountTransfer') }}
          </p>
          <p class="title mb-2 primary--text">
            {{ totalPrice | formatNumber }} {{ $t('shopinfo.topUp.currency') }}
          </p>
          <div class="d-flex caption">
            <span class="mr-3">
              {{ $t('shopinfo.topUp.totalAndVat') }}
            </span>
            <div>
              <p class="mb-1">
                {{ $t('shopinfo.topUp.vat3') }}
              </p>
              <p class="mb-0">
                {{ $t('shopinfo.topUp.pleaseContact') }}
                <span class="green--text">
                  {{ $t('shopinfo.topUp.contactLine') }}
                </span>
              </p>
            </div>
          </div>
          <p class="subtitle-1 mb-5">
            {{ $t('shopinfo.topUp.vatInvoiceDetail') }}
          </p>
        </div>
      </v-col>
      <v-col
        cols="6"
        md="7"
        :class="xs ? 'pt-3 pl-0' : 'pl-5'">
        <div class="d-flex payment-field mb-5">
          <div class="mr-5">
            <v-img
              src="https://giftcard.scb.co.th/assets/images/scb-logo.png"
              max-width="65">
            </v-img>
          </div>
          <div>
            <p class="mb-2">
              {{ $t('shopinfo.topUp.bankSCB') }}
            </p>
            <p class="mb-2">
              {{ $t('shopinfo.topUp.scbBankName') }}
            </p>
            <p class="mb-2">
              {{ $t('shopinfo.topUp.scbBankNumber') }}
            </p>
          </div>
          <v-img
            class="bank-img"
            src="/imgs/package/bank.png"
            :max-width="xs ? 80 : 130">
          </v-img>
        </div>
        <v-spacer />
        <div class="d-flex payment-field">
          <div class="mr-5">
            <v-img
              src="https://www.vtkgarment.com/images/customer/UOB.png"
              max-width="65">
            </v-img>
          </div>
          <div>
            <p class="mb-2">
              {{ $t('shopinfo.topUp.bankUOB') }}
            </p>
            <p class="mb-2">
              {{ $t('shopinfo.topUp.uobBankName') }}
            </p>
            <p class="mb-2">
              {{ $t('shopinfo.topUp.uobBankNumber') }}
            </p>
          </div>
          <v-img
            class="bank-img"
            src="/imgs/package/bank.png"
            :max-width="xs ? 80 : 130">
          </v-img>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-end">
      <v-btn
        color="primary"
        text
        @click="backStep()">
        {{ $t('shopinfo.topUp.back') }}
      </v-btn>
      <v-btn
        color="primary"
        rounded
        depressed
        @click="nextStep()">
        {{ $t('shopinfo.topUp.next') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { GET_PLANS } from '@/resources/graphql/query/Plans'

export default {
  name: 'SelectPayment',
  apollo: {
    plans: () => ({
      query: GET_PLANS
    })
  },
  props: {
    stepper: {
      type: Number,
      default: 1
    },
    packageId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      plans: []
    }
  },
  computed: {
    totalPrice () {
      let result = 0
      const plan = this.plans.find((item) => item.id === this.packageId)
      if (plan) {
        result = plan.price * 1.07
      }
      return result
    },
    xs () {
      return this.$vuetify.breakpoint.xs
    }
  },
  methods: {
    backStep () {
      this.$emit('update:stepper', this.stepper - 1)
    },
    nextStep () {
      this.$emit('update:stepper', 3)
    }
  },
  filters: {
    formatNumber(val) {
      return Number(val).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
    }
  }
}
</script>

<style scoped>
  .payment-field {
    width: 100%;
    position: relative;
    height: 185px;
    padding: 24px 24px 0px 24px;
    border: 1px solid #1BA7E1;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(27, 167, 225, 0.15);
    border-radius: 4px
  }

  .payment-field.left {
    height: auto;
  }

  .bank-img {
    position: absolute;
    right: 20px;
    bottom: 0;
  }
</style>
