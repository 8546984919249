var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "mt-4",
          attrs: { readonly: _vm.loading, "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit()
            }
          },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("text-field-label", {
                    attrs: { label: _vm.$t("shopinfo.topUp.transferTo") }
                  }),
                  _c("v-select", {
                    attrs: {
                      rules: _vm.bankRules,
                      placeholder: _vm.$t(
                        "shopinfo.topUp.placeholder.transferTo"
                      ),
                      items: _vm.displayBanks,
                      "item-text": "name",
                      "item-value": "value",
                      attach: "",
                      dense: "",
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.form.bank,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "bank", $$v)
                      },
                      expression: "form.bank"
                    }
                  }),
                  _c("text-field-label", {
                    attrs: { label: _vm.$t("shopinfo.topUp.pay") }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.amountRules,
                      placeholder: _vm.$t("shopinfo.topUp.placeholder.pay"),
                      type: "number",
                      dense: "",
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.form.amount,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "amount", $$v)
                      },
                      expression: "form.amount"
                    }
                  }),
                  _c("text-field-label", {
                    attrs: { label: _vm.$t("shopinfo.topUp.dateTimeTransfer") }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.dateTimeRules,
                      type: "datetime-local",
                      dense: "",
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.form.dateTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "dateTime", $$v)
                      },
                      expression: "form.dateTime"
                    }
                  }),
                  _c("text-field-label", {
                    attrs: { label: _vm.$t("shopinfo.topUp.telephone") }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.telephoneRules,
                      placeholder: _vm.$t(
                        "shopinfo.topUp.placeholder.telephone"
                      ),
                      dense: "",
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.form.tel,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tel", $$v)
                      },
                      expression: "form.tel"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("text-field-label", {
                    attrs: { label: _vm.$t("shopinfo.topUp.transferSlip") }
                  }),
                  _c("upload", {
                    staticStyle: { "max-width": "300px" },
                    attrs: {
                      value: _vm.form.slip,
                      confirm: false,
                      name: "upload_package_slip"
                    },
                    on: {
                      submit: function($event) {
                        return _vm.uploadSlip($event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-end" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary", text: "" },
              on: {
                click: function($event) {
                  return _vm.backStep()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.back")) + " ")]
          ),
          _c("save-change-button", {
            staticClass: "mr-1",
            attrs: {
              state: _vm.state,
              disabled: !_vm.valid,
              label: _vm.$t("shopinfo.topUp.submitTransfer")
            },
            on: {
              click: function($event) {
                return _vm.submit()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }