<template>
  <div class="text-center">
    <v-img
      :src="image.success"
      max-width="130"
      class="mx-auto mb-2">
    </v-img>
    <p class="primary--text">
      {{ $t('shopinfo.topUp.topUpCreditSuccess') }}
    </p>
    <p class="mb-2">
      {{ $t('shopinfo.topUp.yourTopUpCredit') }}
      {{ selectedCredit }}
      {{ $t('shopinfo.topUp.credit') }}
    </p>
    <p>
      {{ $t('shopinfo.topUp.topUpCreditSuccessDetail') }}
    </p>
    <div class="text-right mt-6">
      <v-btn
        color="primary"
        rounded
        depressed
        @click="closeModal()">
        <span>
          {{ $t('shopinfo.topUp.successTransfer') }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreditFinishStep',
  props: {
    closeModal: {
      type: Function,
      default: () => null
    },
    selectedCredit: {
      type: Number,
      default: 100
    }
  },
  data () {
    return {
      image: {
        success: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/package/add-credit.png`
      }
    }
  }
}
</script>

<style>

</style>
