<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      :readonly="loading"
      class="mt-4"
      lazy-validation
      @submit.prevent="submit()">
      <v-row>
        <v-col>
          <text-field-label
            :label="$t('shopinfo.topUp.transferTo')">
          </text-field-label>
          <v-select
            v-model="form.bank"
            :rules="bankRules"
            :placeholder="$t('shopinfo.topUp.placeholder.transferTo')"
            :items="displayBanks"
            item-text="name"
            item-value="value"
            attach
            dense
            outlined
            required>
          </v-select>
          <text-field-label
            :label="$t('shopinfo.topUp.pay')">
          </text-field-label>
          <v-text-field
            v-model="form.amount"
            :rules="amountRules"
            :placeholder="$t('shopinfo.topUp.placeholder.pay')"
            type="number"
            dense
            outlined
            required />
          <text-field-label
            :label="$t('shopinfo.topUp.dateTimeTransfer')">
          </text-field-label>
          <v-text-field
            v-model="form.dateTime"
            :rules="dateTimeRules"
            type="datetime-local"
            dense
            outlined
            required />
          <text-field-label
            :label="$t('shopinfo.topUp.telephone')">
          </text-field-label>
          <v-text-field
            v-model="form.tel"
            :rules="telephoneRules"
            :placeholder="$t('shopinfo.topUp.placeholder.telephone')"
            dense
            outlined
            required />
        </v-col>
        <v-col>
          <text-field-label
            :label="$t('shopinfo.topUp.transferSlip')">
          </text-field-label>
          <upload
            :value="form.slip"
            :confirm="false"
            name="upload_package_slip"
            style="max-width: 300px;"
            @submit="uploadSlip($event)">
          </upload>
        </v-col>
      </v-row>
    </v-form>
    <div class="d-flex justify-end">
      <v-btn
        color="primary"
        text
        @click="backStep()">
        {{ $t('shopinfo.topUp.back') }}
      </v-btn>
      <save-change-button
        class="mr-1"
        :state="state"
        :disabled="!valid"
        :label="$t('shopinfo.topUp.submitTransfer')"
        @click="submit()">
      </save-change-button>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/Form/Upload.vue'
import { BUY_PLAN } from '@/resources/graphql'

export default {
  name: 'Transfer',
  components: {
    Upload
  },
  props: {
    stepper: {
      type: Number,
      default: 1
    },
    packageId: {
      type: [String, Number],
      required: true
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      file: null,
      menu: false,
      displayBanks: [
        {
          name: 'ไทยพาณิชย์',
          value: 'scb'
        },
        {
          name: 'UOB',
          value: 'uob'
        }
      ],
      bankRules: [
        (v) => !!v || this.$t('shopinfo.validate.bank')
      ],
      amountRules: [
        (v) => !!v || this.$t('shopinfo.validate.amount')
      ],
      dateTimeRules: [
        (v) => !!v || this.$t('shopinfo.validate.dateTime')
      ],
      telephoneRules: [
        (v) => !!v || this.$t('shopinfo.validate.telephone')
      ],
      valid: true,
      state: 'ready'
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    }
  },
  methods: {
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    uploadSlip (e) {
      this.form.slip = e.url
      this.file = e.file
    },
    backStep () {
      this.$refs.form.resetValidation()
      this.$emit('update:stepper', this.stepper - 1)
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: BUY_PLAN,
          variables: {
            input: {
              planId: this.packageId,
              paymentBank: this.form.bank,
              paidAmount: this.form.amount,
              paymentTime: this.$dayjs(this.form.dateTime).format('DD/MM/YYYY H:mm'),
              contactInfo: this.form.tel,
              paymentSlip: this.file
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          this.state = 'success'
          this.$refs.form.resetValidation()
          this.$emit('update:stepper', 4)
        }
        setTimeout(() => {
          this.state = 'ready'
        }, 3000)
      }
    }
  }
}
</script>

<style scoped>
  .upload-slip {
    width: 80%;
    height: 150px;
    background: #F9F9F9;
    border: 1px dashed #E4E4E4;
    border-radius: 2px;
  }
</style>
