import gql from 'graphql-tag'

export const GET_PLANS = gql`
  query {
    plans {
      id
      maxResellers
      maxStocks
      maxUsers
      months
      name
      planType
      price
    }
  }
`

export default {
  GET_PLANS
}
