var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex flex-wrap justify-space-between" },
      [
        _c("package-card", {
          attrs: {
            text: _vm.packages[0],
            value: _vm.quiteProPackage,
            description: _vm.$t("shopinfo.topUp.packageDetail.quitePro"),
            "package-id": _vm.currentId,
            "package-name": _vm.packageName,
            "package-image": _vm.image.quitePro
          },
          on: {
            "update:packageId": function($event) {
              _vm.currentId = $event
            },
            "update:package-id": function($event) {
              _vm.currentId = $event
            },
            "update:packageName": function($event) {
              _vm.packageName = $event
            },
            "update:package-name": function($event) {
              _vm.packageName = $event
            }
          }
        }),
        _c("package-card", {
          attrs: {
            text: _vm.packages[1],
            value: _vm.proPackage,
            description: _vm.$t("shopinfo.topUp.packageDetail.pro"),
            "package-id": _vm.currentId,
            "package-name": _vm.packageName,
            "package-image": _vm.image.pro
          },
          on: {
            "update:packageId": function($event) {
              _vm.currentId = $event
            },
            "update:package-id": function($event) {
              _vm.currentId = $event
            },
            "update:packageName": function($event) {
              _vm.packageName = $event
            },
            "update:package-name": function($event) {
              _vm.packageName = $event
            }
          }
        }),
        _c("package-card", {
          attrs: {
            text: _vm.packages[2],
            value: _vm.trulyProPackage,
            description: _vm.$t("shopinfo.topUp.packageDetail.trulyPro"),
            "package-id": _vm.currentId,
            "package-name": _vm.packageName,
            "package-image": _vm.image.trulyPro
          },
          on: {
            "update:packageId": function($event) {
              _vm.currentId = $event
            },
            "update:package-id": function($event) {
              _vm.currentId = $event
            },
            "update:packageName": function($event) {
              _vm.packageName = $event
            },
            "update:package-name": function($event) {
              _vm.packageName = $event
            }
          }
        }),
        _c("package-card", {
          attrs: {
            text: _vm.packages[3],
            value: _vm.extremelyProPackage,
            description: _vm.$t("shopinfo.topUp.packageDetail.extremelyPro"),
            "package-id": _vm.currentId,
            "package-name": _vm.packageName,
            "package-image": _vm.image.extremelyPro
          },
          on: {
            "update:packageId": function($event) {
              _vm.currentId = $event
            },
            "update:package-id": function($event) {
              _vm.currentId = $event
            },
            "update:packageName": function($event) {
              _vm.packageName = $event
            },
            "update:package-name": function($event) {
              _vm.packageName = $event
            }
          }
        }),
        _c("package-card", {
          attrs: {
            text: _vm.packages[4],
            value: _vm.privatePackage,
            description: _vm.$t("shopinfo.topUp.packageDetail.privateLive"),
            "package-id": _vm.currentId,
            "package-name": _vm.packageName,
            "package-image": _vm.image.privateLive
          },
          on: {
            "update:packageId": function($event) {
              _vm.currentId = $event
            },
            "update:package-id": function($event) {
              _vm.currentId = $event
            },
            "update:packageName": function($event) {
              _vm.packageName = $event
            },
            "update:package-name": function($event) {
              _vm.packageName = $event
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex justify-space-between mt-4" },
      [
        _c(
          "a",
          {
            attrs: {
              href: "https://package." + _vm.domainName + "/",
              target: "_blank"
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.moreDetail")) + " ")]
        ),
        _c(
          "v-btn",
          {
            attrs: { color: "primary", rounded: "", depressed: "" },
            on: {
              click: function($event) {
                return _vm.nextStep()
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.next")) + " ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }