<template>
  <div class="shop-info">
    <header-label
      :label="$t('shopinfo.header')"
      prepend-icon="$g_shopinfo" />
    <v-card
      class="mb-4"
      flat>
      <v-card-text>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4">
            <upload
              name="shop_logo"
              :value="setting.logoUrl"
              style="width: 300px;"
              class="mr-6"
              show-delete
              @remove="remove()"
              @submit="upload($event)">
            </upload>
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="pl-4">
            <div
              v-if="edit"
              class="d-flex input-group">
              <v-text-field
                v-model="setting.name"
                dense
                outlined
                style="max-width: 200px;"
                @keydown.enter="updateShop('name')" />
              <v-btn
                color="primary"
                depressed
                @click="updateShop('name')">
                ยืนยัน
              </v-btn>
            </div>
            <div
              v-else
              class="d-flex">
              <header-label
                :label="setting.name"
                class="mr-2" />
              <v-btn
                color="primary"
                icon
                @click="openUpdateShop()">
                <v-icon>
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
            </div>
            <div
              v-if="isShipnity"
              class="d-flex align-center">
              <text-field-label :label="$t('shopinfo.availableAndReservedCredit')" />
              <text-field-label
                :label="setting.availableAndReservedCredit.toFixed(2)"
                class="hightlight--text ml-3" />
              <text-field-label
                :label="$t('shopinfo.currency')"
                class="hightlight--text ml-1 mr-2" />
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    icon
                    color="primary"
                    class="mb-1"
                    v-bind="attrs"
                    v-on="on">
                    <v-icon>
                      mdi-help-circle-outline
                    </v-icon>
                  </v-btn>
                </template>
                <p class="mb-0">
                  {{ $t('shopinfo.tooltip.hint1') }}
                </p>
                <p class="mb-0">
                  {{ $t('shopinfo.tooltip.hint2') }}
                </p>
              </v-tooltip>
              <a
                v-if="!setting.availableAndReservedCredit"
                class="erroralert--text subtitle-1 text-decoration-underline ml-3 mb-1"
                @click="openTopUpCredit()">{{ $t('shopinfo.addCredit') }}</a>
            </div>
            <div
              v-if="isShipnity"
              class="d-flex align-center">
              <text-field-label :label="$t('shopinfo.yourPackage')" />
              <span class="ml-2 mr-2"> : </span>
              <text-field-label
                :label="setting.packageName"
                class="hightlight--text mr-2" />
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    icon
                    color="primary"
                    class="mb-1"
                    v-bind="attrs"
                    v-on="on">
                    <v-icon>
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </template>
                <p class="mb-0">
                  {{ $t('shopinfo.tooltip.information') }}
                  {{ setting.maxStocks }}
                  {{ $t('shopinfo.tooltip.stock') }}
                </p>
                <p class="mb-0">
                  {{ setting.maxUsers }}
                  {{ $t('shopinfo.tooltip.admin') }}
                  {{ setting.maxResellers }}
                  {{ $t('shopinfo.tooltip.agent') }}
                </p>
              </v-tooltip>
              <text-field-label
                :label="$t('shopinfo.planExpiration')"
                class="ml-3" />
              <text-field-label
                v-if="checkDateExpired"
                :label="$t('shopinfo.packageExpired')"
                class="erroralert--text ml-2" />
              <text-field-label
                v-else
                :label="setting.planExpiration | formatDateNoTime"
                class="ml-2" />
              <a
                v-if="checkDateExpired"
                class="erroralert--text subtitle-1 text-decoration-underline ml-2 mb-1"
                @click="openTopUpPackage()">
                {{ $t('shopinfo.addPackage') }}
              </a>
            </div>
            <div
              v-if="setting.reportAdvanceExpiredAt"
              class="subtitle-1 mb-3">
              {{ $t('shopinfo.advancePlan') }}
              <span class="primary--text"> {{ $t('shopinfo.report') }} </span>
              {{ $t('shopinfo.planExpiration') }} {{ setting.reportAdvanceExpiredAt | formatDateNoTime }}
            </div>
            <v-btn
              v-if="isShipnity"
              color="primary"
              class="mr-2"
              rounded
              depressed
              @click="openTopUpPackage()">
              <v-icon small>
                mdi-transfer-up
              </v-icon>
              <span>
                {{ $t('shopinfo.upgradePackage') }}
              </span>
            </v-btn>
            <v-btn
              v-if="isShipnity"
              color="primary"
              outlined
              @click="openTopUpCredit()">
              <span>
                {{ $t('shopinfo.addCredit') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-form
      ref="form"
      style="posiotion: relative;"
      :readonly="isReadonly"
      lazy-validation
      @submit.prevent="submit()">
      <v-card flat>
        <v-card-text>
          <shop-info-form
            ref="shopInfoForm"
            :address.sync="form.address"
            :zipcode.sync="form.zipcode"
            :tumbon.sync="form.tumbon"
            :amphoe.sync="form.amphoe"
            :province.sync="form.province"
            :tel.sync="form.tel"
            :tax-id.sync="form.taxId"
            :order-link-color.sync="form.orderLinkColor"
            :show-address.sync="form.showAddress">
          </shop-info-form>
          <div class="text-right">
            <save-change-button
              class="mr-1"
              :state="state"
              :disabled="!valid"
              :label="$t('shopinfo.shopInfoForm.save')"
              @click="submit()">
            </save-change-button>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
    <v-dialog
      v-model="creditModal"
      max-width="700"
      @click:outside="closeTopUpCredit">
      <top-up-credit
        ref="topUpCredit"
        :credit-modal.sync="creditModal" />
    </v-dialog>
    <v-dialog
      v-model="packageModal"
      :max-width="packageStep > 2 ? '700' : '1100'"
      @click:outside="closeTopUpPackage">
      <top-up-package
        ref="topUpPackage"
        :package-modal.sync="packageModal"
        :package-step.sync="packageStep">
      </top-up-package>
    </v-dialog>
  </div>
</template>

<script>
import TopUpCredit from '@/views/ShopInfo/components/TopUpCredit/TopUpCredit'
import TopUpPackage from '@/views/ShopInfo/components/TopUpPackage/TopupPackage'
import ShopInfoForm from '@/views/ShopInfo/components/ShopInfoForm'
import Upload from '@/components/Form/Upload.vue'
import { GET_SETTING_SHOPINFO } from '@/resources/graphql/query/Setting'
import { UPDATE_SETTING } from '@/resources/graphql'
import { mapActions } from 'vuex'

export default {
  name: 'ShopInfo',
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_SHOPINFO,
      result ({ data: { setting } }) {
        this.form.address = setting.address
        this.form.tel = setting.tel
        this.form.taxId = setting.taxId
        if (setting.orderLinkColor) {
          this.form.orderLinkColor = setting.orderLinkColor
        } else {
          this.form.orderLinkColor = '#FF0000'
        }
        this.form.showAddress = setting.showAddress

        this.setLoading(false)
      }
    })
  },
  components: {
    TopUpCredit,
    TopUpPackage,
    ShopInfoForm,
    Upload
  },
  data () {
    return {
      creditModal: false,
      packageModal: false,
      packageStep: 1,
      state: 'ready',
      valid: true,
      edit: false,
      setting: {
        name: '',
        availableAndReservedCredit: 0,
        packageName: ''
      },
      form: {
        address: '',
        zipcode: '',
        tumbon: '',
        amphoe: '',
        province: '',
        tel: '',
        taxId: '',
        orderLinkColor: null,
        showAddress: true
      }
    }
  },
  computed: {
    checkDateExpired () {
      return this.$dayjs(this.$dayjs(this.setting.planExpiration)).isBefore(this.$dayjs())
    },
    domainName () {
      const host = window.location.hostname
      const parts = host.split('.')
      if (parts.length === 3) {
        return `${parts[1]}.${parts[2]}`
      } if (parts.length === 2) {
        return `${parts[0]}.${parts[1]}`
      }
      return parts[0]
    },
    isShipnity () {
      return this.domainName === 'shipnity.com' || this.domainName === 'localhost'
    },
    isReadonly () {
      return this.state === 'loading'
    }
  },
  mounted () {
    if (this.$route.query.pop === 'credit') {
      this.creditModal = true
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    openUpdateShop () {
      this.edit = true
    },
    async updateShop (field) {
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.setting[field]
          }
        }
      }).catch(() => {})
      if (res) {
        this.edit = false
      }
    },
    async remove () {
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            removeLogo: true
          }
        }
      }).catch(() => {})
      if (res) {
        this.refetch()
        setTimeout(() => {
          this.$refs.shopInfoForm.setAutoAddress()
        }, 500)
      }
    },
    async upload (objFile) {
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            logo: objFile.file
          }
        }
      }).catch(() => {})
      if (res) {
        this.refetch()
        setTimeout(() => {
          this.$refs.shopInfoForm.setAutoAddress()
        }, 500)
      }
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const address = this.form.address
        const tumbon = this.form.tumbon
        const amphoe = this.form.amphoe
        const province = this.form.province
        const zipcode = this.form.zipcode

        let fullAddress = ''
        if (province.includes('กรุงเทพ')) {
          fullAddress = `${address}`
          fullAddress += tumbon ? ` แขวง${tumbon}` : ''
          fullAddress += amphoe ? ` เขต${amphoe}` : ''
          fullAddress += province ? ` จังหวัด${province} ${zipcode}` : ''
        } else {
          fullAddress = `${address}`
          fullAddress += tumbon ? ` ตำบล${tumbon}` : ''
          fullAddress += amphoe ? ` อำเภอ${amphoe}` : ''
          fullAddress += province ? ` จังหวัด${province} ${zipcode}` : ''
        }

        const res = await this.$apollo.mutate({
          mutation: UPDATE_SETTING,
          variables: {
            input: {
              tel: this.form.tel,
              taxId: this.form.taxId,
              address: fullAddress,
              orderLinkColor: this.form.orderLinkColor,
              showAddress: this.form.showAddress
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          this.state = 'success'
        }
        setTimeout(() => {
          this.state = 'ready'
        }, 3000)
      }
    },
    refetch () {
      this.$apollo.queries.setting.refetch()
    },
    closeTopUpCredit () {
      this.$refs.topUpCredit.closeModal()
    },
    closeTopUpPackage () {
      this.$refs.topUpPackage.closeModal()
    },
    openTopUpCredit () {
      this.creditModal = true
    },
    openTopUpPackage () {
      this.packageModal = true
    }
  }
}
</script>

<style>
  .v-stepper__step {
    padding: 12px;
  }

  .v-stepper--alt-labels .v-stepper__header .v-divider {
    margin: 25px -67px 0;
  }
  .shop-info .color-picker {
    flex-direction: row-reverse;
  }
  .shop-info .color-input:hover {
    border-color: #4d4d4d;
  }

  .shop-info .color-input:focus {
    outline: none;
    border: 2px solid #008dc5;
  }
  .shop-info .color-input {
    margin-left: 10px;
    border: 1px solid #9E9E9E;
    max-height: 40px;
  }

  .shop-info .hightlight--text {
    color: var(--v-primary-base) !important;
  }

  .shop-info .erroralert--text {
    color: var(--v-error-base) !important;
  }

  .shop-info .input-group .v-text-field--outlined fieldset {
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .shop-info .input-group .v-btn {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 40px !important;
  }
</style>
