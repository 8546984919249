<template>
  <div>
    <div class="d-flex flex-wrap justify-space-between">
      <package-card
        :text="packages[0]"
        :value="quiteProPackage"
        :description="$t('shopinfo.topUp.packageDetail.quitePro')"
        :package-id.sync="currentId"
        :package-name.sync="packageName"
        :package-image="image.quitePro">
      </package-card>
      <package-card
        :text="packages[1]"
        :value="proPackage"
        :description="$t('shopinfo.topUp.packageDetail.pro')"
        :package-id.sync="currentId"
        :package-name.sync="packageName"
        :package-image="image.pro">
      </package-card>
      <package-card
        :text="packages[2]"
        :value="trulyProPackage"
        :description="$t('shopinfo.topUp.packageDetail.trulyPro')"
        :package-id.sync="currentId"
        :package-name.sync="packageName"
        :package-image="image.trulyPro">
      </package-card>
      <package-card
        :text="packages[3]"
        :value="extremelyProPackage"
        :description="$t('shopinfo.topUp.packageDetail.extremelyPro')"
        :package-id.sync="currentId"
        :package-name.sync="packageName"
        :package-image="image.extremelyPro">
      </package-card>
      <package-card
        :text="packages[4]"
        :value="privatePackage"
        :description="$t('shopinfo.topUp.packageDetail.privateLive')"
        :package-id.sync="currentId"
        :package-name.sync="packageName"
        :package-image="image.privateLive">
      </package-card>
    </div>
    <div class="d-flex justify-space-between mt-4">
      <a
        :href="`https://package.${domainName}/`"
        target="_blank">
        {{ $t('shopinfo.topUp.moreDetail') }}
      </a>
      <v-btn
        color="primary"
        rounded
        depressed
        @click="nextStep()">
        {{ $t('shopinfo.topUp.next') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import PackageCard from '@/views/ShopInfo/components/TopUpPackage/components/PackageCard'
import { GET_SETTING_SHOPINFO } from '@/resources/graphql/query/Setting'
import { GET_PLANS } from '@/resources/graphql/query/Plans'

export default {
  name: 'SelectPackage',
  apollo: {
    plans: () => ({
      fetchPolicy: 'no-cache',
      query: GET_PLANS
    }),
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_SHOPINFO,
      result ({ data: { setting } }) {
        this.packageName = setting.packageName
        this.currentId = this.packageId
      }
    })
  },
  components: {
    PackageCard
  },
  props: {
    stepper: {
      type: Number,
      default: 1
    },
    packageId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      plans: [],
      packages: [
        'Quite Pro',
        'Pro',
        'Truly Pro',
        'Extremely Pro',
        'Private Live'
      ],
      packageName: '',
      currentId: '',
      image: {
        quitePro: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/package/quite-pro.png`,
        pro: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/package/turm-pro.png`,
        trulyPro: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/package/truly-pro.png`,
        extremelyPro: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/package/extremely-pro.png`,
        privateLive: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/package/private-live.png`
      }
    }
  },
  computed: {
    quiteProPackage () {
      return this.plans.filter((item) => item.maxStocks === 1)
    },
    proPackage () {
      return this.plans.filter((item) => item.maxStocks === 2)
    },
    trulyProPackage () {
      return this.plans.filter((item) => item.maxStocks === 3)
    },
    extremelyProPackage () {
      return this.plans.filter((item) => item.maxStocks === 6000)
    },
    privatePackage () {
      return this.plans.filter((item) => item.maxStocks === 7000)
    },
    domainName () {
      const host = window.location.hostname
      const parts = host.split('.')
      if (parts.length === 3) {
        return `${parts[1]}.${parts[2]}`
      } if (parts.length === 2) {
        return `${parts[0]}.${parts[1]}`
      }
      return parts[0]
    }
  },
  watch: {
    currentId (val) {
      this.$emit('update:packageId', val)
    }
  },
  methods: {
    nextStep () {
      this.$emit('update:stepper', 2)
    }
  }
}
</script>
