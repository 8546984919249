<template>
  <div class="shop-form">
    <v-row
      class="mb-4"
      no-gutters>
      <v-col
        md="8"
        sm="12">
        <div class="d-flex align-center">
          <text-field-label
            :label="$t('shopinfo.shopInfoForm.address')"
            prepend-icon="mdi-map-marker-outline">
          </text-field-label>
          <v-switch
            :input-value="showAddress"
            class="ml-4"
            inset
            @change="openConfirmPopup($event)">
          </v-switch>
        </div>
        <v-textarea
          :value="address"
          :placeholder="$t('shopinfo.placeholder.address')"
          rows="8"
          no-resize
          outlined
          class="mt-2"
          @input="updateShopInfoForm('address', $event)">
        </v-textarea>
        <span
          v-if="addr1"
          class="recommend-address-text"
          v-html="addr1Html">
        </span>&nbsp;
        <a
          v-if="addr1"
          class="btn-apply"
          @click="replaceAddress(1)">ปรับใช้</a>
        <br v-if="addr2" />
        <span
          v-if="addr2"
          class="recommend-address-text"
          v-html="addr2Html">
        </span>&nbsp;
        <a
          v-if="addr2"
          class="btn-apply"
          @click="replaceAddress(2)">ปรับใช้</a>
        <br v-if="addr3" />
        <span
          v-if="addr3"
          class="recommend-address-text"
          v-html="addr3Html">
        </span>&nbsp;
        <a
          v-if="addr3"
          class="btn-apply"
          @click="replaceAddress(3)">ปรับใช้</a>
      </v-col>
      <v-col
        class="pl-5"
        md="4"
        sm="12">
        <text-field-label
          :label="$t('shopinfo.shopInfoForm.color')"
          prepend-icon="mdi-palette-swatch-outline">
        </text-field-label>
        <v-color-picker
          :value="orderLinkColor"
          :dot-size="25"
          mode="hexa"
          @input="updateShopInfoForm('orderLinkColor', $event)">
        </v-color-picker>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="mb-4">
      <v-col
        cols="12"
        md="4"
        class="pr-5">
        <text-field-label
          :label="$t('shopinfo.shopInfoForm.tumbon')">
        </text-field-label>
        <v-text-field
          :value="tumbon"
          :placeholder="$t('shopinfo.placeholder.tumbon')"
          class="mt-2"
          outlined
          dense
          @input="updateShopInfoForm('tumbon', $event)" />
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="pr-5">
        <text-field-label
          :label="$t('shopinfo.shopInfoForm.amphoe')">
        </text-field-label>
        <v-text-field
          :value="amphoe"
          :placeholder="$t('shopinfo.placeholder.amphoe')"
          class="mt-2"
          outlined
          dense
          @input="updateShopInfoForm('amphoe', $event)" />
      </v-col>
      <v-col
        cols="12"
        md="4">
        <text-field-label
          :label="$t('shopinfo.shopInfoForm.province')">
        </text-field-label>
        <v-text-field
          :value="province"
          :placeholder="$t('shopinfo.placeholder.province')"
          class="mt-2"
          outlined
          dense
          @input="updateShopInfoForm('province', $event)" />
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="mb-4">
      <v-col
        cols="12"
        md="4"
        class="pr-5">
        <text-field-label
          :label="$t('shopinfo.shopInfoForm.zipcode')">
        </text-field-label>
        <v-text-field
          :value="zipcode"
          :placeholder="$t('shopinfo.placeholder.zipcode')"
          class="mt-2"
          outlined
          dense
          @input="updateShopInfoForm('zipcode', $event)" />
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="pr-5">
        <text-field-label
          :label="$t('shopinfo.shopInfoForm.telephone')">
        </text-field-label>
        <v-text-field
          :value="tel"
          :placeholder="$t('shopinfo.placeholder.telephone')"
          class="mt-2"
          outlined
          dense
          @input="updateShopInfoForm('tel', $event)" />
      </v-col>
      <v-col
        cols="12"
        md="4">
        <text-field-label
          :label="$t('shopinfo.shopInfoForm.taxId')"
          prepend-icon="mdi-note-outline">
        </text-field-label>
        <v-text-field
          :value="taxId"
          :placeholder="$t('shopinfo.placeholder.taxId')"
          class="mt-2"
          outlined
          dense
          @input="updateShopInfoForm('taxId', $event)" />
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="500"
      persistent>
      <v-card>
        <v-card-text class="pt-3">
          <text-field-label label="ปิดการแสดงที่อยู่บนใบปะกล่อง" />
          <text-field-label label="ใบปะกล่องทุกรูปแบบจะไม่มีที่อยู่ทั้งหมด หากปิดตัวเลือกนี้" />
          <div class="text-right mt-4">
            <v-btn
              text
              @click="closeConfirmPopup()">
              ยกเลิก
            </v-btn>
            <v-btn
              color="#FF7575"
              class="white--text"
              rounded
              depressed
              @click="submitConfirmPopup()">
              ยืนยัน
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import addreszer from '@/assets/js/addreszer'
import { UPDATE_SETTING } from '@/resources/graphql'

export default {
  name: 'ShopInfoForm',
  props: {
    address: {
      type: String,
      default: ''
    },
    zipcode: {
      type: String,
      default: ''
    },
    tumbon: {
      type: String,
      default: ''
    },
    amphoe: {
      type: String,
      default: ''
    },
    province: {
      type: String,
      default: ''
    },
    tel: {
      type: String,
      default: ''
    },
    taxId: {
      type: String,
      default: ''
    },
    orderLinkColor: {
      type: [Object, String],
      default: null
    },
    showAddress: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dialog: false,
      loading: false,
      // nameRules: [
      //   (v) => !!v || this.$t('shopinfo.validate.name')
      // ],
      colorRules: [
        (v) => !!v || this.$t('shopinfo.validate.color')
      ],
      // addressRules: [
      //   (v) => !!v || this.$t('shopinfo.validate.address')
      // ],
      // zipcodeRules: [
      //   (v) => !!v || this.$t('shopinfo.validate.zipcode')
      // ],
      // tumbonRules: [
      //   (v) => !!v || this.$t('shopinfo.validate.tumbon')
      // ],
      // amphoeRules: [
      //   (v) => !!v || this.$t('shopinfo.validate.amphoe')
      // ],
      // provinceRules: [
      //   (v) => !!v || this.$t('shopinfo.validate.province')
      // ],
      // telephoneRules: [
      //   (v) => !!v || this.$t('shopinfo.validate.telephone')
      // ],
      // taxIdRules: [
      //   (v) => !!v || this.$t('shopinfo.validate.taxId')
      // ],
      addr1: '',
      addr1Html: '',
      zipcode1: '',
      tumbon1: '',
      amphoe1: '',
      province1: '',

      addr2: '',
      addr2Html: '',
      zipcode2: '',
      tumbon2: '',
      amphoe2: '',
      province2: '',

      addr3: '',
      addr3Html: '',
      zipcode3: '',
      tumbon3: '',
      amphoe3: '',
      province3: ''
    }
  },
  mounted () {
    setTimeout(async () => {
      if (this.address) {
        this.setAutoAddress()
      }
    }, 500)
  },
  methods: {
    setAutoAddress () {
      let str1 = ''
      let str2 = ''
      let str3 = ''
      let str4 = ''
      let address = ''
      let district = ''
      let amphoe = ''
      let province = ''
      let zipcode = ''

      if (this.address.includes('แขวง') || this.address.includes('ตำบล')) {
        str1 = this.address.includes('แขวง') ? this.address.split(' แขวง') : this.address.split(' ตำบล')
        address = str1[0]
        this.$emit('update:address', address)
      }

      if (this.address.includes('เขต') || this.address.includes('อำเภอ')) {
        str2 = this.address.includes('เขต') ? str1[1].split(' เขต') : str1[1].split(' อำเภอ')
        district = str2[0]
      }

      if (this.address.includes('จังหวัด')) {
        str3 = str2[1].split(' จังหวัด')
        amphoe = str3[0]
      }

      if (str3) {
        str4 = str3[1].split(' ')
        province = str4[0]
        zipcode = str4[1]
      }

      this.$emit('update:tumbon', district)
      this.$emit('update:amphoe', amphoe)
      this.$emit('update:province', province)
      this.$emit('update:zipcode', zipcode)
    },
    setAutoDefaultAddress () {
      this.$emit('update:address', this.addr1)
      this.$emit('update:zipcode', this.zipcode1)
      this.$emit('update:tumbon', this.tumbon1)
      this.$emit('update:amphoe', this.amphoe1)
      this.$emit('update:province', this.province1)

      this.addr1 = ''
      this.addr1Html = ''
      this.zipcode1 = ''
      this.tumbon1 = ''
      this.amphoe1 = ''
      this.province1 = ''

      this.addr2 = ''
      this.addr2Html = ''
      this.zipcode2 = ''
      this.tumbon2 = ''
      this.amphoe2 = ''
      this.province2 = ''

      this.addr3 = ''
      this.addr3Html = ''
      this.zipcode3 = ''
      this.tumbon3 = ''
      this.amphoe3 = ''
      this.province3 = ''
    },
    replaceAddress (pos) {
      if (pos === 1) {
        this.$emit('update:address', this.addr1)
        this.$emit('update:zipcode', this.zipcode1)
        this.$emit('update:tumbon', this.tumbon1)
        this.$emit('update:amphoe', this.amphoe1)
        this.$emit('update:province', this.province1)
      } else if (pos === 2) {
        this.$emit('update:address', this.addr2)
        this.$emit('update:zipcode', this.zipcode2)
        this.$emit('update:tumbon', this.tumbon2)
        this.$emit('update:amphoe', this.amphoe2)
        this.$emit('update:province', this.province2)
      } else if (pos === 3) {
        this.$emit('update:address', this.addr3)
        this.$emit('update:zipcode', this.zipcode3)
        this.$emit('update:tumbon', this.tumbon3)
        this.$emit('update:amphoe', this.amphoe3)
        this.$emit('update:province', this.province3)
      }
      this.addr1 = ''
      this.addr1Html = ''
      this.zipcode1 = ''
      this.tumbon1 = ''
      this.amphoe1 = ''
      this.province1 = ''

      this.addr2 = ''
      this.addr2Html = ''
      this.zipcode2 = ''
      this.tumbon2 = ''
      this.amphoe2 = ''
      this.province2 = ''

      this.addr3 = ''
      this.addr3Html = ''
      this.zipcode3 = ''
      this.tumbon3 = ''
      this.amphoe3 = ''
      this.province3 = ''
    },
    // eslint-disable-next-line func-names
    suggestAddress: _.debounce(function (e) {
      const context = e
      if (context.length > 10) {
        const addr1 = addreszer(context, 0.3, 0.65)

        if (addr1 && addr1.data) {
          const address = `<span class="book--text">${addr1.address}</span>`
          const district = addr1.correct.includes(addr1.data.district)
          ? `แขวง${addr1.data.district}` : `<span class="red--text">แขวง${addr1.data.district}</span>`
          const amphoe = addr1.correct.includes(addr1.data.amphoe)
          ? `เขต${addr1.data.amphoe}` : `<span class="red--text">เขต${addr1.data.amphoe}</span>`
          const province = addr1.correct.includes(addr1.data.province)
          ? `จังหวัด${addr1.data.province}` : `<span class="red--text">จังหวัด${addr1.data.province}</span>`
          const zipcode = addr1.correct.includes(addr1.data.zipcode)
          ? `${addr1.data.zipcode}` : `<span class="red--text">${addr1.data.zipcode}</span>`
          const note = addr1.data.note.length === 0
          ? '' : `<span class="grey--text">(${addr1.data.note})</span>`

          this.addr1Html = `<span>${address} ${district} ${amphoe} ${province} ${zipcode} ${note}</span>`
          this.addr1 = addr1.address
          this.zipcode1 = addr1.data.zipcode
          this.tumbon1 = addr1.data.district
          this.amphoe1 = addr1.data.amphoe
          this.province1 = addr1.data.province
        } else {
          this.addr1 = ''
          this.addr1Html = ''
          this.zipcode1 = ''
          this.tumbon1 = ''
          this.amphoe1 = ''
          this.province1 = ''
        }

        const addr2 = addreszer(context, 0.3, 0.5)
        if (addr2 && addr2.data && addr2.data.district_code !== addr1.data.district_code) {
          const address = `<span class="book--text">${addr2.address}</span>`
          const district = addr2.correct.includes(addr2.data.district)
          ? `แขวง${addr2.data.district}` : `<span class="red--text">แขวง${addr2.data.district}</span>`
          const amphoe = addr2.correct.includes(addr2.data.amphoe)
          ? `เขต${addr2.data.amphoe}` : `<span class="red--text">เขต${addr2.data.amphoe}</span>`
          const province = addr2.correct.includes(addr2.data.province)
          ? `จังหวัด${addr2.data.province}` : `<span class="red--text">จังหวัด${addr2.data.province}</span>`
          const zipcode = addr2.correct.includes(addr2.data.zipcode)
          ? `${addr2.data.zipcode}` : `<span class="red--text">${addr2.data.zipcode}</span>`
          const note = addr2.data.note.length === 0
          ? '' : `<span class="grey--text">(${addr2.data.note})</span>`

          this.addr2Html = `<span>${address} ${district} ${amphoe} ${province} ${zipcode} ${note}</span>`
          this.addr2 = addr2.address
          this.zipcode2 = addr2.data.zipcode
          this.tumbon2 = addr2.data.district
          this.amphoe2 = addr2.data.amphoe
          this.province2 = addr2.data.province
        } else {
          this.addr2 = ''
          this.addr2Html = ''
          this.zipcode2 = ''
          this.tumbon2 = ''
          this.amphoe2 = ''
          this.province2 = ''
        }

        const addr3 = addreszer(context, 0.3, 0.1)
        if (addr3 && addr3.data && addr3.data.district_code !== addr2.data.district_code) {
          const address = `<span class="book--text">${addr3.address}</span>`
          const district = addr3.correct.includes(addr3.data.district)
          ? `แขวง${addr3.data.district}` : `<span class="red--text">แขวง${addr3.data.district}</span>`
          const amphoe = addr3.correct.includes(addr3.data.amphoe)
          ? `เขต${addr3.data.amphoe}` : `<span class="red--text">เขต${addr3.data.amphoe}</span>`
          const province = addr3.correct.includes(addr3.data.province)
          ? `จังหวัด${addr3.data.province}` : `<span class="red--text">จังหวัด${addr3.data.province}</span>`
          const zipcode = addr3.correct.includes(addr3.data.zipcode)
          ? `${addr3.data.zipcode}` : `<span class="red--text">${addr3.data.zipcode}</span>`
          const note = addr3.data.note.length === 0
          ? '' : `<span class="grey--text">(${addr3.data.note})</span>`

          this.addr3Html = `<span>${address} ${district} ${amphoe} ${province} ${zipcode} ${note}</span>`
          this.addr3 = addr3.address
          this.zipcode3 = addr3.data.zipcode
          this.tumbon3 = addr3.data.district
          this.amphoe3 = addr3.data.amphoe
          this.province3 = addr3.data.province
        } else {
          this.addr3 = ''
          this.addr3Html = ''
          this.zipcode3 = ''
          this.tumbon3 = ''
          this.amphoe3 = ''
          this.province3 = ''
        }
      }
    }, 850),
    updateShopInfoForm (name, val) {
      this.suggestAddress(val)
      this.$emit(`update:${name}`, val)
    },
    closeConfirmPopup () {
      this.dialog = false
      this.$emit('update:showAddress', true)
    },
    openConfirmPopup (val) {
      if (!val) {
        this.dialog = true
        this.$emit('update:showAddress', val)
      } else {
        this.$emit('update:showAddress', val)
      }
    },
    async submitConfirmPopup () {
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            showAddress: this.showAddress
          }
        }
      }).catch(() => {})
      if (res) {
        this.dialog = false
      }
    }
  }
}
</script>

<style>
  .shop-form .v-color-picker__edit button {
    display: none;
  }

  .shop-form .v-color-picker__edit span {
    display: none;
  }

  .shop-form .v-color-picker__edit input {
    height: 40px;
    border-color: #9E9E9E !important;
    text-align: left;
    padding-left: 12px;
  }

  .shop-form .v-color-picker__dot {
    border: 1px solid #9E9E9E;
  }

  .shop-form .v-color-picker__input:not(:last-child) {
    margin-right: 0px;
  }

  .shop-form .v-color-picker__controls {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .shop-form .v-color-picker__preview {
    padding-right: 20px;
  }
</style>
