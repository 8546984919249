<template>
  <div>
    <v-card
      :max-width="190"
      :class="{ 'blue lighten-5' : checkGroupPackage}"
      class="pack-card"
      flat>
      <v-card-text @click="onClickedPackage()">
        <v-img
          :src="packageImage"
          max-width="130"
          class="mx-auto mb-2">
        </v-img>
        <p class="primary--text text-center font-weight-medium mb-4">
          {{ text }}
        </p>
        <v-select
          v-if="currentPackage"
          :value="currentPackage.id"
          :items="value"
          :disabled="!checkGroupPackage"
          item-text="name"
          item-value="id"
          class="mb-4 white"
          dense
          outlined
          hide-details
          @input="updatePackage($event)">
        </v-select>
        <div
          v-if="currentPackage"
          class="mb-3">
          <span>
            {{ $t('shopinfo.topUp.price') }}
          </span>
          <span>
            {{ currentPackage.price.toFixed(2) }}
          </span>
          <span>
            {{ $t('shopinfo.topUp.currency') }}
          </span>
        </div>
        <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
        <p class="descriiption disabled--text">{{ description }}</p>
        <div
          v-if="currentPackage && currentPlan"
          class="mt-2">
          <v-btn
            color="#ED9F2A"
            class="white--text"
            style="pointer-events: none;"
            rounded
            depressed>
            {{ $t('shopinfo.topUp.currentPlan') }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { GET_SETTING_SHOPINFO } from '@/resources/graphql/query/Setting'

export default {
  name: 'PackageCard',
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_SHOPINFO,
      result ({ data: { setting } }) {
        if (setting.currentPlan) {
          this.plan = setting.currentPlan
        } else {
          this.plan = {
            maxStocks: 1
          }
          this.$emit('update:packageName', 'Quite Pro')
        }
      }
    })
  },
  props: {
    text: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    packageId: {
      type: String,
      default: ''
    },
    packageName: {
      type: String,
      default: ''
    },
    packageImage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      plan: null,
      currentPackage: null,
      setting: {}
    }
  },
  computed: {
    currentPlan () {
      return this.plan.maxStocks === this.currentPackage.maxStocks
    },
    checkGroupPackage () {
      return this.text === this.packageName
    }
  },
  watch: {
    packageId () {
      this.checkPackage()
    }
  },
  mounted () {
    setTimeout(() => {
      this.checkPackage()
    }, 300)
  },
  methods: {
    checkPackage () {
      const pack = this.value.find((item) => item.id === this.packageId)
      if (pack) {
        this.currentPackage = pack
      } else {
        this.currentPackage = this.value[0]
      }
    },
    updatePackage (val) {
      this.$emit('update:packageId', val)
    },
    onClickedPackage () {
      if (this.packageName !== this.text) {
        this.$emit('update:packageName', this.text)
        this.$emit('update:packageId', this.value[0].id)
      }
    }
  }
}
</script>

<style scoped>
  .pack-card {
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    height: 100%;
  }

  .descriiption {
    font-size: 13px;
    white-space: pre;
  }
</style>
