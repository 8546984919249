/* eslint-disable no-unused-vars */
import _ from 'lodash'
import zipcodeDatabase from './json/zipcode_raw_database.json'

function stringScore (conversation, word, fuzziness) {
  // If the string is equal to the word, perfect match.
  if (conversation === word) { return 1 }

  // if it's not a perfect match and is empty return 0
  if (word === '') { return 0 }

  let runningScore = 0
      let charScore
      let finalScore
      const string = conversation
      const lString = string.toLowerCase()
      const strLength = string.length
      const lWord = word.toLowerCase()
      const wordLength = word.length
      let idxOf
      let startAt = 0
      let fuzzies = 1
      let fuzzyFactor
      let i

  // Cache fuzzyFactor for speed increase
  if (fuzziness) { fuzzyFactor = 1 - fuzziness }

  // Walk through word and add up scores.
  // Code duplication occurs to prevent checking fuzziness inside for loop
  if (fuzziness) {
    for (i = 0; i < wordLength; i += 1) {
      // Find next first case-insensitive match of a character.
      idxOf = lString.indexOf(lWord[i], startAt)

      if (idxOf === -1) {
        fuzzies += fuzzyFactor
      } else {
        if (startAt === idxOf) {
          // Consecutive letter & start-of-string Bonus
          charScore = 0.7
        } else {
          charScore = 0.1

          // Acronym Bonus
          // Weighing Logic: Typing the first character of an acronym is as if you
          // preceded it with two perfect character matches.
          if (string[idxOf - 1] === ' ') { charScore += 0.8 }
        }

        // Same case bonus.
        if (string[idxOf] === word[i]) { charScore += 0.1 }

        // Update scores and startAt position for next round of indexOf
        runningScore += charScore
        startAt = idxOf + 1
      }
    }
  } else {
    for (i = 0; i < wordLength; i += 1) {
      idxOf = lString.indexOf(lWord[i], startAt)
      if (idxOf === -1) { return 0 }

      if (startAt === idxOf) {
        charScore = 0.7
      } else {
        charScore = 0.1
        if (string[idxOf - 1] === ' ') { charScore += 0.8 }
      }
      if (string[idxOf] === word[i]) { charScore += 0.1 }
      runningScore += charScore
      startAt = idxOf + 1
    }
  }

  // Reduce penalty for longer strings.
  finalScore = (0.5 * (runningScore / strLength + runningScore / wordLength)) / fuzzies

  if ((lWord[0] === lString[0]) && (finalScore < 0.85)) {
    finalScore += 0.15
  }

  return finalScore
}

function matcher (context, items, params, rating = 0.01) {
  const scores = []
  for (let i = 0; i < items.length; i++) {
    for (let j = 0; j < params.length; j++) {
      const score = stringScore(context, items[i][params[j]], 0.9)
      if (rating <= score) {
        scores.push({
          ...items[i],
          score
        })
        break
      }
    }
  }
  return scores
}

function isNumeric (value) {
  return /^-?\d+$/.test(value)
}

export default function strToAddress (context, accurate = 0.3, min = 0.5) {
  let string = context
  string = string.replace('เเ', 'แ')
  string = string.replace('หมุ่', 'หมู่')
  string = string.replace('เขต', ' ')
  string = string.replace('แขวง', ' ')
  string = string.replace('จังหวัด', ' ')
  string = string.replace('อำเภอ', ' ')
  string = string.replace('ตำบล', ' ')
  string = string.replace('ซ.', ' ')
  string = string.replace('จ.', ' ')
  string = string.replace('ต.', ' ')
  string = string.replace('อ.', ' ')
  string = string.replace('ซ .', ' ')
  string = string.replace('จ .', ' ')
  string = string.replace('ต .', ' ')
  string = string.replace('อ .', ' ')
  string = string.replace('รหัสไปรษณีย์', ' ')
  string = string.replace('กทม.', ' กรุงเทพมหานคร')
  string = string.replace('ก.ท.ม.', ' กรุงเทพมหานคร')
  string = string.replace('ก.ทม.', ' กรุงเทพมหานคร')
  string = string.replace('กท.ม.', ' กรุงเทพมหานคร')
  string = string.replace('กทม', ' กรุงเทพมหานคร')
  string = string.replace('กรุงเทพ', ' กรุงเทพมหานคร')
  string = string.replace('มหานครมหานคร', 'มหานคร')
  string = string.replace('สุราดทานี', ' สุราษฎร์ธานี')
  string = string.replace(/\\r?\\n|\\r/ig, ' ')
  string = string.replace(/\r?\n|\r/ig, ' ')
  string = string.replace(/\d+[/]\d+/ig, ' $& ')
  string = string.replace(/\s\s+/ig, ' ')
  string = string.replace(/\s\s+/ig, ' ')
  string = string.replace(/\s\s+/ig, ' ')
  string = string.replace(/\s\s+/ig, ' ')
  string = string.replace(/\s\s+/ig, ' ')
  string = string.replace(/\s\s+/ig, ' ')
  string = string.replace(/\s\s+/ig, ' ')

  let address = string.split(' ')

  string = string.replace(/\d+[/]\d+/ig, '')
  string = string.replace('ที่อยู่', ' ')
  string = string.replace('บ้านเลขที่', ' ')
  string = string.replace('อยู่', ' ')
  string = string.replace('ตรงข้าม', ' ')
  string = string.replace('ข้าง', ' ')
  string = string.replace('หมู่บ้าน', ' ')
  string = string.replace('แยก', ' ')
  string = string.replace('เลขที่', ' ')
  string = string.replace('ซอย', ' ')
  // string = string.replace('ถนน', '')
  string = string.replace('รหัส', ' ')
  string = string.replace('ชั้น', ' ')
  string = string.replace('ห้อง', ' ')
  string = string.replace('ชื่อ', ' ')
  string = string.replace('นาย', ' ')
  string = string.replace('นางสาว', ' ')
  string = string.replace('นาง', ' ')
  string = string.replace('ฯ', '')
  string = string.replace('ถ.', ' ')
  string = string.replace('ถ .', ' ')
  string = string.replace(/[.]/ig, ' ')
  string = string.replace(/[0-9]{10,10}/ig, '')
  string = string.replace(/[0-9]{9,10}/ig, '')
  string = string.replace(/[0-9]{5,5}/ig, ' $& ')
  string = string.replace(/[0-9]{4,4}$/ig, ' ')
  string = string.replace(/\([^()]*\)/ig, ' ')
  string = string.replace(/\s\s+/ig, ' ')
  string = string.replace(/\s\s+/ig, ' ')
  string = string.replace(/\s\s+/ig, ' ')
  string = string.replace(/\s\s+/ig, ' ')
  string = string.replace(/\s\s+/ig, ' ')
  string = string.replace(/\s\s+/ig, ' ')
  string = string.replace(/\s\s+/ig, ' ')
  // string = string.replace(/[^0-9A-Za-zก-ฮ๐-๙// ]/ig, '')

  let zipcode = ''
  const zParts = string.split(' ').reverse()
  // let sParts = []
  for (let i = 0; i < zParts.length; i++) {
    if (zParts[i].length === 5 && isNumeric(zParts[i])) {
      zipcode = zParts[i]
      address = address.filter((str) => str !== zParts[i])
      zParts[i] = ''
      break
    }
  }

  // sParts = sParts.reverse()
  // string = sParts.join(' ')
  // string = string.replace(/[/]/ig, '')
  // string = string.replace(/\d+/ig, '')
  let winner = null
  const correct = []
  const parts = zParts.filter((item) => isNumeric(item) || item.length > 2)

  const provinces = []
  for (let i = 0; i < parts.length; i++) {
    const list = matcher(
      parts[i],
      zipcodeDatabase.data,
      ['province'],
      accurate
    )
    const max = _.maxBy(list, 'score')

    provinces.push({
      ...max,
      searchIndex: i
    })
  }
  // console.log('p', provinces)
  const p = _.maxBy(provinces, 'score')
  if (p) {
    const isPHeightScore = p.score > min
    let pData = zipcodeDatabase.data
    if (isPHeightScore) {
      address = address.filter((str) => str !== parts[p.searchIndex])
      const spl = parts.splice(p.searchIndex, 1)
      correct.push(...spl)
      pData = zipcodeDatabase.data.filter((item) => item.province === p.province)
    }
    // console.log(parts)
    const amphoes = []
    for (let i = 0; i < parts.length; i++) {
      const list = matcher(
        parts[i],
        pData,
        ['amphoe'],
        accurate
      )
      const max = _.maxBy(list, 'score')
      amphoes.push({
        ...max,
        searchIndex: i
      })
    }
    // console.log('a', amphoes)
    // if (zipcode) {
    //   const za = amphoes.filter((item) => item.zipcode === zipcode)
    //   if (za.length) {
    //     correct.push(zipcode)
    //     amphoes = za
    //   }
    // }
    const a = _.maxBy(amphoes, 'score')
    if (a) {
      const isAHeightScore = a.score > min
      let aData = pData
      if (zipcode) {
        const za = aData.filter((item) => item.zipcode === zipcode)
        if (za.length) {
          correct.push(zipcode)
          aData.push(...za)
        }
      }
      if (isAHeightScore) {
        address = address.filter((str) => str !== parts[a.searchIndex])
        const spl = parts.splice(a.searchIndex, 1)
        correct.push(...spl)
        aData = zipcodeDatabase.data.filter((item) => item.amphoe === a.amphoe)
      }
      // console.log(parts)
      const districts = []
      for (let i = 0; i < parts.length; i++) {
        const list = matcher(
          parts[i],
          aData,
          ['district'],
          accurate
        )
        const max = _.maxBy(list, 'score')
        districts.push({
          ...max,
          searchIndex: i
        })
      }
      // console.log('d', districts)
      const d = _.maxBy(districts, 'score')
      if (d) {
        // const isDHeightScore = d.score > min
        let dData = aData
        // if (isDHeightScore) {
          address = address.filter((str) => str !== parts[d.searchIndex])
          const splParts = parts
          const spl = splParts.splice(d.searchIndex, 1)
          correct.push(...spl)
          dData = zipcodeDatabase.data.filter((item) => item.district_code === d.district_code)
        // }
        // console.log(parts)
        const notes = []
        for (let i = 0; i < parts.length; i++) {
          const list = matcher(
            parts[i],
            dData,
            ['note'], 0.1
          )
          const max = _.maxBy(list, 'score')
          notes.push({
            ...max,
            searchIndex: i
          })
        }
        // console.log('n', notes)
        const n = _.maxBy(notes, 'score')
        if (n) {
          winner = {
            data: n,
            address: address.filter((item) => item.length > 0).join(' '),
            correct
          }
        } else {
          winner = {
            data: d,
            address: address.filter((item) => item.length > 0).join(' '),
            correct
          }
        }
      } else {
        winner = {
          data: a,
          address: address.filter((item) => item.length > 0).join(' '),
          correct
        }
      }
    } else {
      winner = {
        data: p,
        address: address.filter((item) => item.length > 0).join(' '),
        correct
      }
    }
  }

  // console.log('winner', winner)
  return winner
}
