<template>
  <div class="topup-package">
    <v-card flat>
      <v-stepper
        v-model="stepper"
        class="pt-4"
        alt-labels>
        <div class="close-popup">
          <v-btn
            small
            icon
            @click="closeModal()">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-stepper-header class="px-4 ">
          <v-stepper-step
            :complete="stepper > 1"
            step="1">
            {{ $t('shopinfo.topUp.step.selectPackage') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :complete="stepper > 2"
            step="2">
            {{ $t('shopinfo.topUp.step.bankAccount') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :complete="stepper > 3"
            step="3">
            {{ $t('shopinfo.topUp.step.transfer') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :complete="stepper === 4"
            step="4">
            {{ $t('shopinfo.topUp.step.transferred') }}
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-content step="1">
          <select-package
            :stepper.sync="stepper"
            :package-id.sync="packageId">
          </select-package>
        </v-stepper-content>
        <v-stepper-content step="2">
          <select-payment
            :stepper.sync="stepper"
            :package-id="packageId">
          </select-payment>
        </v-stepper-content>
        <v-stepper-content step="3">
          <transfer
            ref="transfer"
            :stepper.sync="stepper"
            :package-id="packageId"
            :form.sync="form">
          </transfer>
        </v-stepper-content>
        <v-stepper-content step="4">
          <top-up-success
            :stepper.sync="stepper"
            :package-id="packageId"
            :close-modal="closeModal" />
        </v-stepper-content>
      </v-stepper>
    </v-card>
  </div>
</template>

<script>
import SelectPackage from '@/views/ShopInfo/components/TopUpPackage/components/SelectPackage.vue'
import SelectPayment from '@/views/ShopInfo/components/TopUpPackage/components/SelectPayment.vue'
import Transfer from '@/views/ShopInfo/components/TopUpPackage/components/Transfer.vue'
import TopUpSuccess from '@/views/ShopInfo/components/TopUpPackage/components/TopUpSuccess.vue'
import { GET_SETTING_SHOPINFO } from '@/resources/graphql/query/Setting'

export default {
  name: 'TopupPackage',
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_SHOPINFO,
      result ({ data: { setting } }) {
        this.packageId = setting.currentPlan.id
      }
    })
  },
  components: {
    SelectPackage,
    SelectPayment,
    Transfer,
    TopUpSuccess
  },
  props: {
    packageModal: {
      type: Boolean,
      default: false
    },
    packageStep: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      stepper: 1,
      setting: {},
      packageId: '',
      bank: '',
      form: {
        bank: '',
        amount: null,
        dateTime: '',
        tel: '',
        slip: null
      }
    }
  },
  computed: {
    xs () {
      return this.$vuetify.breakpoint.xs
    }
  },
  watch: {
    stepper (val) {
      this.$emit('update:packageStep', val)
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:packageModal', false)
      this.form = {
        bank: '',
        amount: null,
        dateTime: '',
        tel: '',
        slip: null
      }
      this.stepper = 1
      this.$refs.transfer.resetValidation()
    }
  }
}
</script>

<style>
  .topup-package .v-stepper {
    box-shadow: none;
  }

  .topup-package .v-stepper__header {
    box-shadow: none;
  }

  @media screen and (max-width: 600px) {
    .v-stepper__header {
      flex-wrap: nowrap;
    }
  }
</style>
