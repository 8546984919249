var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c("v-img", {
        staticClass: "mx-auto mb-2",
        attrs: { src: _vm.image.success, "max-width": "130" }
      }),
      _c("p", { staticClass: "primary--text" }, [
        _vm._v(" " + _vm._s(_vm.$t("shopinfo.topUp.topUpCreditSuccess")) + " ")
      ]),
      _c("p", { staticClass: "mb-2" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("shopinfo.topUp.yourTopUpCredit")) +
            " " +
            _vm._s(_vm.selectedCredit) +
            " " +
            _vm._s(_vm.$t("shopinfo.topUp.credit")) +
            " "
        )
      ]),
      _c("p", [
        _vm._v(
          " " + _vm._s(_vm.$t("shopinfo.topUp.topUpCreditSuccessDetail")) + " "
        )
      ]),
      _c(
        "div",
        { staticClass: "text-right mt-6" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary", rounded: "", depressed: "" },
              on: {
                click: function($event) {
                  return _vm.closeModal()
                }
              }
            },
            [
              _c("span", [
                _vm._v(
                  " " + _vm._s(_vm.$t("shopinfo.topUp.successTransfer")) + " "
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }