<template>
  <div>
    <div class="payment-field d-flex">
      <v-img
        src="https://giftcard.scb.co.th/assets/images/scb-logo.png"
        class="mt-2 mr-5"
        max-width="65"
        max-height="65">
      </v-img>
      <div>
        <p class="mb-2">
          {{ $t('shopinfo.topUp.bankSCB') }}
        </p>
        <p class="mb-2">
          {{ $t('shopinfo.topUp.scbBankName') }}
        </p>
        <p class="mb-2">
          {{ $t('shopinfo.topUp.scbBankNumber') }}
        </p>
      </div>
      <v-img
        class="bank-img"
        src="/imgs/package/bank.png">
      </v-img>
    </div>
    <div class="payment-field d-flex">
      <v-img
        src="https://www.vtkgarment.com/images/customer/UOB.png"
        class="mt-2 mr-5"
        max-width="65"
        max-height="50">
      </v-img>
      <div>
        <p class="mb-2">
          {{ $t('shopinfo.topUp.bankUOB') }}
        </p>
        <p class="mb-2">
          {{ $t('shopinfo.topUp.uobBankName') }}
        </p>
        <p class="mb-2">
          {{ $t('shopinfo.topUp.uobBankNumber') }}
        </p>
      </div>
      <v-img
        class="bank-img"
        src="/imgs/package/bank.png">
      </v-img>
    </div>
    <div class="text-right">
      <v-btn
        color="primary"
        rounded
        depressed
        @click="nextStep()">
        <span>
          {{ $t('shopinfo.topUp.next') }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreditPayment',
  props: {
    stepper: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      payment: null
    }
  },
  methods: {
    nextStep () {
      this.$emit('update:stepper', 2)
    },
    selectPayment (pay) {
      this.payment = pay
    }
  }
}
</script>

<style scoped>
  .payment-field {
    position: relative;
    margin-bottom: 16px;
    padding: 24px;
    border: 1px solid #1BA7E1;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(27, 167, 225, 0.15);
    border-radius: 4px;
    cursor: pointer;
  }

  .bank-img {
    position: absolute;
    right: 20px;
    bottom: 0;
  }
</style>
