var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop-form" },
    [
      _c(
        "v-row",
        { staticClass: "mb-4", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { md: "8", sm: "12" } },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("text-field-label", {
                    attrs: {
                      label: _vm.$t("shopinfo.shopInfoForm.address"),
                      "prepend-icon": "mdi-map-marker-outline"
                    }
                  }),
                  _c("v-switch", {
                    staticClass: "ml-4",
                    attrs: { "input-value": _vm.showAddress, inset: "" },
                    on: {
                      change: function($event) {
                        return _vm.openConfirmPopup($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c("v-textarea", {
                staticClass: "mt-2",
                attrs: {
                  value: _vm.address,
                  placeholder: _vm.$t("shopinfo.placeholder.address"),
                  rows: "8",
                  "no-resize": "",
                  outlined: ""
                },
                on: {
                  input: function($event) {
                    return _vm.updateShopInfoForm("address", $event)
                  }
                }
              }),
              _vm.addr1
                ? _c("span", {
                    staticClass: "recommend-address-text",
                    domProps: { innerHTML: _vm._s(_vm.addr1Html) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.addr1
                ? _c(
                    "a",
                    {
                      staticClass: "btn-apply",
                      on: {
                        click: function($event) {
                          return _vm.replaceAddress(1)
                        }
                      }
                    },
                    [_vm._v("ปรับใช้")]
                  )
                : _vm._e(),
              _vm.addr2 ? _c("br") : _vm._e(),
              _vm.addr2
                ? _c("span", {
                    staticClass: "recommend-address-text",
                    domProps: { innerHTML: _vm._s(_vm.addr2Html) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.addr2
                ? _c(
                    "a",
                    {
                      staticClass: "btn-apply",
                      on: {
                        click: function($event) {
                          return _vm.replaceAddress(2)
                        }
                      }
                    },
                    [_vm._v("ปรับใช้")]
                  )
                : _vm._e(),
              _vm.addr3 ? _c("br") : _vm._e(),
              _vm.addr3
                ? _c("span", {
                    staticClass: "recommend-address-text",
                    domProps: { innerHTML: _vm._s(_vm.addr3Html) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.addr3
                ? _c(
                    "a",
                    {
                      staticClass: "btn-apply",
                      on: {
                        click: function($event) {
                          return _vm.replaceAddress(3)
                        }
                      }
                    },
                    [_vm._v("ปรับใช้")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-5", attrs: { md: "4", sm: "12" } },
            [
              _c("text-field-label", {
                attrs: {
                  label: _vm.$t("shopinfo.shopInfoForm.color"),
                  "prepend-icon": "mdi-palette-swatch-outline"
                }
              }),
              _c("v-color-picker", {
                attrs: {
                  value: _vm.orderLinkColor,
                  "dot-size": 25,
                  mode: "hexa"
                },
                on: {
                  input: function($event) {
                    return _vm.updateShopInfoForm("orderLinkColor", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-4", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "pr-5", attrs: { cols: "12", md: "4" } },
            [
              _c("text-field-label", {
                attrs: { label: _vm.$t("shopinfo.shopInfoForm.tumbon") }
              }),
              _c("v-text-field", {
                staticClass: "mt-2",
                attrs: {
                  value: _vm.tumbon,
                  placeholder: _vm.$t("shopinfo.placeholder.tumbon"),
                  outlined: "",
                  dense: ""
                },
                on: {
                  input: function($event) {
                    return _vm.updateShopInfoForm("tumbon", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pr-5", attrs: { cols: "12", md: "4" } },
            [
              _c("text-field-label", {
                attrs: { label: _vm.$t("shopinfo.shopInfoForm.amphoe") }
              }),
              _c("v-text-field", {
                staticClass: "mt-2",
                attrs: {
                  value: _vm.amphoe,
                  placeholder: _vm.$t("shopinfo.placeholder.amphoe"),
                  outlined: "",
                  dense: ""
                },
                on: {
                  input: function($event) {
                    return _vm.updateShopInfoForm("amphoe", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c("text-field-label", {
                attrs: { label: _vm.$t("shopinfo.shopInfoForm.province") }
              }),
              _c("v-text-field", {
                staticClass: "mt-2",
                attrs: {
                  value: _vm.province,
                  placeholder: _vm.$t("shopinfo.placeholder.province"),
                  outlined: "",
                  dense: ""
                },
                on: {
                  input: function($event) {
                    return _vm.updateShopInfoForm("province", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-4", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "pr-5", attrs: { cols: "12", md: "4" } },
            [
              _c("text-field-label", {
                attrs: { label: _vm.$t("shopinfo.shopInfoForm.zipcode") }
              }),
              _c("v-text-field", {
                staticClass: "mt-2",
                attrs: {
                  value: _vm.zipcode,
                  placeholder: _vm.$t("shopinfo.placeholder.zipcode"),
                  outlined: "",
                  dense: ""
                },
                on: {
                  input: function($event) {
                    return _vm.updateShopInfoForm("zipcode", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pr-5", attrs: { cols: "12", md: "4" } },
            [
              _c("text-field-label", {
                attrs: { label: _vm.$t("shopinfo.shopInfoForm.telephone") }
              }),
              _c("v-text-field", {
                staticClass: "mt-2",
                attrs: {
                  value: _vm.tel,
                  placeholder: _vm.$t("shopinfo.placeholder.telephone"),
                  outlined: "",
                  dense: ""
                },
                on: {
                  input: function($event) {
                    return _vm.updateShopInfoForm("tel", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c("text-field-label", {
                attrs: {
                  label: _vm.$t("shopinfo.shopInfoForm.taxId"),
                  "prepend-icon": "mdi-note-outline"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-2",
                attrs: {
                  value: _vm.taxId,
                  placeholder: _vm.$t("shopinfo.placeholder.taxId"),
                  outlined: "",
                  dense: ""
                },
                on: {
                  input: function($event) {
                    return _vm.updateShopInfoForm("taxId", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "pt-3" },
                [
                  _c("text-field-label", {
                    attrs: { label: "ปิดการแสดงที่อยู่บนใบปะกล่อง" }
                  }),
                  _c("text-field-label", {
                    attrs: {
                      label:
                        "ใบปะกล่องทุกรูปแบบจะไม่มีที่อยู่ทั้งหมด หากปิดตัวเลือกนี้"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-right mt-4" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function($event) {
                              return _vm.closeConfirmPopup()
                            }
                          }
                        },
                        [_vm._v(" ยกเลิก ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: "#FF7575",
                            rounded: "",
                            depressed: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.submitConfirmPopup()
                            }
                          }
                        },
                        [_vm._v(" ยืนยัน ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }