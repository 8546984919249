var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop-info" },
    [
      _c("header-label", {
        attrs: {
          label: _vm.$t("shopinfo.header"),
          "prepend-icon": "$g_shopinfo"
        }
      }),
      _c(
        "v-card",
        { staticClass: "mb-4", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c("upload", {
                        staticClass: "mr-6",
                        staticStyle: { width: "300px" },
                        attrs: {
                          name: "shop_logo",
                          value: _vm.setting.logoUrl,
                          "show-delete": ""
                        },
                        on: {
                          remove: function($event) {
                            return _vm.remove()
                          },
                          submit: function($event) {
                            return _vm.upload($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-4", attrs: { cols: "12", md: "8" } },
                    [
                      _vm.edit
                        ? _c(
                            "div",
                            { staticClass: "d-flex input-group" },
                            [
                              _c("v-text-field", {
                                staticStyle: { "max-width": "200px" },
                                attrs: { dense: "", outlined: "" },
                                on: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.updateShop("name")
                                  }
                                },
                                model: {
                                  value: _vm.setting.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.setting, "name", $$v)
                                  },
                                  expression: "setting.name"
                                }
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", depressed: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateShop("name")
                                    }
                                  }
                                },
                                [_vm._v(" ยืนยัน ")]
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("header-label", {
                                staticClass: "mr-2",
                                attrs: { label: _vm.setting.name }
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openUpdateShop()
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(" mdi-square-edit-outline ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                      _vm.isShipnity
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("text-field-label", {
                                attrs: {
                                  label: _vm.$t(
                                    "shopinfo.availableAndReservedCredit"
                                  )
                                }
                              }),
                              _c("text-field-label", {
                                staticClass: "hightlight--text ml-3",
                                attrs: {
                                  label: _vm.setting.availableAndReservedCredit.toFixed(
                                    2
                                  )
                                }
                              }),
                              _c("text-field-label", {
                                staticClass: "hightlight--text ml-1 mr-2",
                                attrs: { label: _vm.$t("shopinfo.currency") }
                              }),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { right: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mb-1",
                                                    attrs: {
                                                      "x-small": "",
                                                      icon: "",
                                                      color: "primary"
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    " mdi-help-circle-outline "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3143043118
                                  )
                                },
                                [
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("shopinfo.tooltip.hint1")
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("shopinfo.tooltip.hint2")
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              ),
                              !_vm.setting.availableAndReservedCredit
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "erroralert--text subtitle-1 text-decoration-underline ml-3 mb-1",
                                      on: {
                                        click: function($event) {
                                          return _vm.openTopUpCredit()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("shopinfo.addCredit"))
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isShipnity
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("text-field-label", {
                                attrs: { label: _vm.$t("shopinfo.yourPackage") }
                              }),
                              _c("span", { staticClass: "ml-2 mr-2" }, [
                                _vm._v(" : ")
                              ]),
                              _c("text-field-label", {
                                staticClass: "hightlight--text mr-2",
                                attrs: { label: _vm.setting.packageName }
                              }),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { right: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mb-1",
                                                    attrs: {
                                                      "x-small": "",
                                                      icon: "",
                                                      color: "primary"
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    " mdi-information-outline "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    94208972
                                  )
                                },
                                [
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("shopinfo.tooltip.information")
                                        ) +
                                        " " +
                                        _vm._s(_vm.setting.maxStocks) +
                                        " " +
                                        _vm._s(
                                          _vm.$t("shopinfo.tooltip.stock")
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.setting.maxUsers) +
                                        " " +
                                        _vm._s(
                                          _vm.$t("shopinfo.tooltip.admin")
                                        ) +
                                        " " +
                                        _vm._s(_vm.setting.maxResellers) +
                                        " " +
                                        _vm._s(
                                          _vm.$t("shopinfo.tooltip.agent")
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              ),
                              _c("text-field-label", {
                                staticClass: "ml-3",
                                attrs: {
                                  label: _vm.$t("shopinfo.planExpiration")
                                }
                              }),
                              _vm.checkDateExpired
                                ? _c("text-field-label", {
                                    staticClass: "erroralert--text ml-2",
                                    attrs: {
                                      label: _vm.$t("shopinfo.packageExpired")
                                    }
                                  })
                                : _c("text-field-label", {
                                    staticClass: "ml-2",
                                    attrs: {
                                      label: _vm._f("formatDateNoTime")(
                                        _vm.setting.planExpiration
                                      )
                                    }
                                  }),
                              _vm.checkDateExpired
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "erroralert--text subtitle-1 text-decoration-underline ml-2 mb-1",
                                      on: {
                                        click: function($event) {
                                          return _vm.openTopUpPackage()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("shopinfo.addPackage")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.setting.reportAdvanceExpiredAt
                        ? _c("div", { staticClass: "subtitle-1 mb-3" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("shopinfo.advancePlan")) + " "
                            ),
                            _c("span", { staticClass: "primary--text" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("shopinfo.report")) + " "
                              )
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("shopinfo.planExpiration")) +
                                " " +
                                _vm._s(
                                  _vm._f("formatDateNoTime")(
                                    _vm.setting.reportAdvanceExpiredAt
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _vm.isShipnity
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                color: "primary",
                                rounded: "",
                                depressed: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openTopUpPackage()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" mdi-transfer-up ")
                              ]),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("shopinfo.upgradePackage")) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isShipnity
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.openTopUpCredit()
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("shopinfo.addCredit")) +
                                    " "
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-form",
        {
          ref: "form",
          staticStyle: { posiotion: "relative" },
          attrs: { readonly: _vm.isReadonly, "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit()
            }
          }
        },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-text",
                [
                  _c("shop-info-form", {
                    ref: "shopInfoForm",
                    attrs: {
                      address: _vm.form.address,
                      zipcode: _vm.form.zipcode,
                      tumbon: _vm.form.tumbon,
                      amphoe: _vm.form.amphoe,
                      province: _vm.form.province,
                      tel: _vm.form.tel,
                      "tax-id": _vm.form.taxId,
                      "order-link-color": _vm.form.orderLinkColor,
                      "show-address": _vm.form.showAddress
                    },
                    on: {
                      "update:address": function($event) {
                        return _vm.$set(_vm.form, "address", $event)
                      },
                      "update:zipcode": function($event) {
                        return _vm.$set(_vm.form, "zipcode", $event)
                      },
                      "update:tumbon": function($event) {
                        return _vm.$set(_vm.form, "tumbon", $event)
                      },
                      "update:amphoe": function($event) {
                        return _vm.$set(_vm.form, "amphoe", $event)
                      },
                      "update:province": function($event) {
                        return _vm.$set(_vm.form, "province", $event)
                      },
                      "update:tel": function($event) {
                        return _vm.$set(_vm.form, "tel", $event)
                      },
                      "update:taxId": function($event) {
                        return _vm.$set(_vm.form, "taxId", $event)
                      },
                      "update:tax-id": function($event) {
                        return _vm.$set(_vm.form, "taxId", $event)
                      },
                      "update:orderLinkColor": function($event) {
                        return _vm.$set(_vm.form, "orderLinkColor", $event)
                      },
                      "update:order-link-color": function($event) {
                        return _vm.$set(_vm.form, "orderLinkColor", $event)
                      },
                      "update:showAddress": function($event) {
                        return _vm.$set(_vm.form, "showAddress", $event)
                      },
                      "update:show-address": function($event) {
                        return _vm.$set(_vm.form, "showAddress", $event)
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c("save-change-button", {
                        staticClass: "mr-1",
                        attrs: {
                          state: _vm.state,
                          disabled: !_vm.valid,
                          label: _vm.$t("shopinfo.shopInfoForm.save")
                        },
                        on: {
                          click: function($event) {
                            return _vm.submit()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700" },
          on: { "click:outside": _vm.closeTopUpCredit },
          model: {
            value: _vm.creditModal,
            callback: function($$v) {
              _vm.creditModal = $$v
            },
            expression: "creditModal"
          }
        },
        [
          _c("top-up-credit", {
            ref: "topUpCredit",
            attrs: { "credit-modal": _vm.creditModal },
            on: {
              "update:creditModal": function($event) {
                _vm.creditModal = $event
              },
              "update:credit-modal": function($event) {
                _vm.creditModal = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": _vm.packageStep > 2 ? "700" : "1100" },
          on: { "click:outside": _vm.closeTopUpPackage },
          model: {
            value: _vm.packageModal,
            callback: function($$v) {
              _vm.packageModal = $$v
            },
            expression: "packageModal"
          }
        },
        [
          _c("top-up-package", {
            ref: "topUpPackage",
            attrs: {
              "package-modal": _vm.packageModal,
              "package-step": _vm.packageStep
            },
            on: {
              "update:packageModal": function($event) {
                _vm.packageModal = $event
              },
              "update:package-modal": function($event) {
                _vm.packageModal = $event
              },
              "update:packageStep": function($event) {
                _vm.packageStep = $event
              },
              "update:package-step": function($event) {
                _vm.packageStep = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }