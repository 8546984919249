<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      :readonly="loading"
      class="mt-4"
      lazy-validation
      @submit.prevent="submit()">
      <v-row>
        <v-col>
          <text-field-label
            :label="$t('shopinfo.topUp.creditQuantity')">
          </text-field-label>
          <v-select
            v-model="form.credit"
            :placeholder="$t('shopinfo.topUp.placeholder.creditQuantity')"
            :items="credit"
            item-text="title"
            item-value="value"
            attach
            dense
            outlined>
          </v-select>
          <text-field-label
            :label="$t('shopinfo.topUp.transferTo')">
          </text-field-label>
          <v-select
            v-model="form.bank"
            :rules="bankRules"
            :placeholder="$t('shopinfo.topUp.placeholder.transferTo')"
            :items="displayBanks"
            item-text="name"
            item-value="value"
            attach
            dense
            outlined
            required>
          </v-select>
          <text-field-label
            :label="$t('shopinfo.topUp.pay')">
          </text-field-label>
          <v-text-field
            v-model="form.amount"
            :rules="amountRules"
            :placeholder="$t('shopinfo.topUp.placeholder.pay')"
            type="number"
            min="0"
            dense
            outlined
            required />
          <text-field-label
            :label="$t('shopinfo.topUp.dateTimeTransfer')">
          </text-field-label>
          <v-text-field
            v-model="form.dateTime"
            :rules="dateTimeRules"
            :placeholder="$t('shopinfo.topUp.placeholder.dateTimeTransfer')"
            type="datetime-local"
            outlined
            dense
            required />
          <text-field-label
            :label="$t('shopinfo.topUp.telephone')">
          </text-field-label>
          <v-text-field
            v-model="form.tel"
            :rules="telephoneRules"
            :placeholder="$t('shopinfo.topUp.placeholder.telephone')"
            dense
            outlined
            required />
        </v-col>
        <v-col>
          <text-field-label
            :label="$t('shopinfo.topUp.transferSlip')">
          </text-field-label>
          <v-img>
          </v-img>
          <upload
            :value="form.slip"
            :confirm="false"
            name="upload_credit_slip"
            style="max-width: 300px;"
            @submit="uploadSlip($event)">
          </upload>
        </v-col>
      </v-row>
      <div class="text-right">
        <v-btn
          color="primary"
          text
          @click="backStep()">
          <span>
            {{ $t('shopinfo.topUp.back') }}
          </span>
        </v-btn>
        <save-change-button
          class="mr-1"
          :state="state"
          :disabled="!valid"
          :label="$t('shopinfo.topUp.submitTransfer')"
          @click="submit()">
        </save-change-button>
      </div>
    </v-form>
  </div>
</template>

<script>
import Upload from '@/components/Form/Upload.vue'
import { BUY_CREDIT } from '@/resources/graphql'

export default {
  name: 'CreditTransfer',
  components: {
    Upload
  },
  props: {
    stepper: {
      type: Number,
      default: 1
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      file: null,
      credit: [
        {
          title: '100 บาท = 100 เครดิต',
          value: 100
        },
        {
          title: '500 บาท = 500 เครดิต',
          value: 500
        },
        {
          title: '2,000 บาท = 2,000 เครดิต',
          value: 2000
        },
        {
          title: '5,000 บาท = 5,000 เครดิต',
          value: 5000
        },
        {
          title: '10,000 บาท = 10,000 เครดิต',
          value: 10000
        },
        {
          title: '20,000 บาท = 20,000 เครดิต',
          value: 20000
        }
      ],
      displayBanks: [
        {
          name: 'ไทยพาณิชย์',
          value: 'scb'
        },
        {
          name: 'UOB',
          value: 'uob'
        }
      ],
      bankRules: [
        (v) => !!v || this.$t('shopinfo.validate.bank')
      ],
      amountRules: [
        (v) => !!v || this.$t('shopinfo.validate.amount')
      ],
      dateTimeRules: [
        (v) => !!v || this.$t('shopinfo.validate.dateTime')
      ],
      telephoneRules: [
        (v) => !!v || this.$t('shopinfo.validate.telephone')
      ],
      valid: true,
      state: 'ready'
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    }
  },
  methods: {
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    uploadSlip (e) {
      this.form.slip = e.url
      this.file = e.file
    },
    backStep () {
      this.$refs.form.resetValidation()
      this.$emit('update:stepper', this.stepper - 1)
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: BUY_CREDIT,
          variables: {
            input: {
              credit: Number(this.form.credit),
              paymentBank: this.form.bank,
              paidAmount: this.form.amount,
              paymentTime: this.$dayjs(this.form.dateTime).format('DD/MM/YYYY H:mm'),
              contactInfo: this.form.tel,
              paymentSlip: this.file
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          this.state = 'success'
          this.$refs.form.resetValidation()
          this.$emit('update:stepper', 3)
        }
        setTimeout(() => {
          this.state = 'ready'
        }, 3000)
      }
    }
  }
}
</script>

<style>
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0px;
  }
</style>

<style scoped>
  .upload-slip {
    width: 80%;
    height: 150px;
    background: #F9F9F9;
    border: 1px dashed #E4E4E4;
    border-radius: 2px;
  }
</style>
