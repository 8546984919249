<template>
  <div class="top-up-success">
    <v-img
      :src="image.success"
      max-width="130"
      class="mx-auto mb-2">
    </v-img>
    <p class="primary--text">
      {{ $t('shopinfo.topUp.transferPackage') }}
      <span v-if="currentPackage"> {{ currentPackage.name }} </span>
      {{ $t('shopinfo.topUp.success') }}
    </p>
    <p>
      {{ $t('shopinfo.topUp.pendingTransferPackage') }}
    </p>
    <p>
      {{ $t('shopinfo.topUp.transferToOpenPackage') }}
    </p>
    <div class="text-right">
      <v-btn
        color="primary"
        rounded
        depressed
        @click="closeModal()">
        {{ $t('shopinfo.topUp.success') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { GET_PLANS } from '@/resources/graphql/query/Plans'

export default {
  name: 'TopUpSuccess',
  apollo: {
    plans: () => ({
      query: GET_PLANS
    })
  },
  props: {
    packageModal: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: () => null
    },
    packageId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      plans: [],
      image: {
        success: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/package/add-package.png`
      }
    }
  },
  computed: {
    currentPackage () {
      const pack = this.plans.find((item) => item.id === this.packageId)
      if (pack) {
        return pack
      }
      return null
    }
  }
}
</script>

<style scoped>
  .top-up-success {
    text-align: center;
  }
</style>
