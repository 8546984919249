<template>
  <div class="topup-credit">
    <v-card>
      <v-stepper
        v-model="stepper"
        class="pt-4"
        alt-labels>
        <div class="close-popup">
          <v-btn
            icon
            @click="closeModal()">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-stepper-header class="px-12">
          <v-stepper-step
            step="1"
            :complete="stepper > 1">
            {{ $t('shopinfo.topUp.step.bankAccount') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="2"
            :complete="stepper > 2">
            {{ $t('shopinfo.topUp.step.transfer') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="3"
            :complete="stepper === 3">
            {{ $t('shopinfo.topUp.step.transferred') }}
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-content
          class="pt-4 pb-4"
          step="1">
          <credit-payment :stepper.sync="stepper" />
        </v-stepper-content>
        <v-stepper-content
          class="pt-4 pb-3"
          step="2">
          <credit-transfer
            ref="transfer"
            :stepper.sync="stepper"
            :form.sync="form">
          </credit-transfer>
        </v-stepper-content>
        <v-stepper-content
          class="pt-4 pb-4"
          step="3">
          <credit-finish-step
            :close-modal="closeModal"
            :selected-credit="form.credit">
          </credit-finish-step>
        </v-stepper-content>
      </v-stepper>
    </v-card>
  </div>
</template>

<script>
import CreditPayment from '@/views/ShopInfo/components/TopUpCredit/components/CreditPayment.vue'
import CreditTransfer from '@/views/ShopInfo/components/TopUpCredit/components/CreditTransfer.vue'
import CreditFinishStep from '@/views/ShopInfo/components/TopUpCredit/components/CreditFinishStep.vue'

export default {
  name: 'TopUpCredit',
  components: {
    CreditPayment,
    CreditTransfer,
    CreditFinishStep
  },
  props: {
    creditModal: {
      type: Boolean,
      deafult: false
    }
  },
  data () {
    return {
      stepper: 1,
      form: {
        credit: 100,
        bank: '',
        amount: null,
        dateTime: null,
        tel: '',
        slip: null
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:creditModal', false)
      this.stepper = 1
      this.form = {
        credit: 100,
        bank: '',
        amount: null,
        dateTime: '',
        tel: '',
        slip: null
      }
      this.$refs.transfer.resetValidation()
    }
  }
}
</script>

<style>
  .topup-credit .v-stepper {
    box-shadow: none;
  }

  .topup-credit .v-stepper__header {
    box-shadow: none;
  }
</style>
